import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import {getText} from "../../../../utilis/generalUtilis";
import {selectCurrentUser} from "../../../../redux/user/userSlice";
import {useSelector} from "react-redux";

const RecipeIngredients = ({
                             title,
                             dataToRender,
                             spaceBetweenItems,
                             dairyFree,
                             glutenFree,
                             servingsCount,
                             servings,
                             printable = false
                           }) => {
  const currentUser = useSelector(selectCurrentUser);
  let unit_preference = currentUser?.unit_preference;
  let result = null;
  const [ingredientList, setIngredientList] = useState([]);

  useEffect(() => {
    if (Array.isArray(dataToRender)) {
      const groupedIngredients = dataToRender.reduce((groups, item) => {
        if (item.ingredient_list_group === null || item.ingredient_list_group === '') {
          groups[0].push(item);
        } else {
          const group = groups.find(g => g[0]?.ingredient_list_group === item.ingredient_list_group);
          if (group) {
            group.push(item);
          } else {
            groups.push([item]);
          }
        }
        return groups;
      }, [[]]);

      setIngredientList(groupedIngredients);
    }
  }, [dataToRender]);
  if (typeof dataToRender === 'string') {
    const paragraphs = dataToRender.split('<p>').filter(paragraph => paragraph !== '' && paragraph !== '</p>');
    result = paragraphs.map((paragraph, index) => {
      return `<h1 class="font-chivo font-bold text-md mt-5">Step ${index + 1} : </h1> <p>` + paragraph;
    }).join('');
  }
  return (
    <div className="pl-2 lg:pl-6">
      {title &&
        <h1 className='font-chivo font-bold text-lg mb-4'>{title}:</h1>}
      <ul>
        <ul>
          {title=="Ingredients" && ingredientList?.map((item, groupIndex) => (
            <li key={groupIndex}>
              {item[0]?.ingredient_list_group && (
                <h2 className='font-bold'>{item[0].ingredient_list_group}</h2>
              )}
              <ul className={`${title === 'Ingredients' ? 'list-disc' : 'list-decimal'} ml-5 lg:mr-0`}>
                {item.map((ingredient, index) => (
                  <li
                    key={index}
                    className={`font-chivo text-sm md:text-base text-wwlBlack ${
                      spaceBetweenItems ? 'mb-2' : ''
                    }`}
                  >
                    {getText(
                      dairyFree,
                      glutenFree,
                      ingredient,
                      servingsCount,
                      servings,
                      unit_preference,
                    )}
                      {ingredient.preppingNotes && <span className={`italic text-xs`}> ({ingredient.preppingNotes})</span>}
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>

      </ul>
      {typeof dataToRender === 'string' &&
      <div dangerouslySetInnerHTML={{__html: result}}
           className={`font-chivo text-sm md:text-base text-wwlBlack ${spaceBetweenItems ? 'mb-2' : ''}`}></div>
      }

    </div>

  );
}
  ;
// '1 TSP paprika',
  RecipeIngredients.propTypes = {
    title: PropTypes.string,
    // dataToRender: PropTypes.array.isRequired //causing warnings , need to fix later
  }

  export default RecipeIngredients;